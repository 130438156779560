import checkAddons from '../../addons/addons';

const appInterface = {
  setBottomMenu: function () {
    // no-op (Use 'Common Layout' option.)
  },
  eventBanner: function () {
    checkAddons(
      'eventBanner',
      function () {
        AppInterface.callApp({
          name: 'eventBanner',
        });
      },
      Object.values(arguments),
    );
  },
  eventBannerV2: function () {
    checkAddons(
      'eventBannerV2',
      function () {
        AppInterface.callApp({
          name: 'eventBanner',
        });
      },
      Object.values(arguments),
    );
  },
  setGlobalFilter: function (type) {
    checkAddons(
      'setGlobalFilter',
      function () {
        AppInterface.callApp({
          name: 'setGlobalFilter',
          param: { type },
        });
      },
      Object.values(arguments),
    );
  },
  popOpened: function (closeScript) {
    AppInterface.callApp({
      name: 'popOpened',
      param: { messageName: closeScript },
    });
  },
  popClosed: function () {
    if (_AppInfo.UpperVersion('4.3.0')) {
      this.popClosedV2('');
    } else {
      if (_AppInfo._os === 'ios') {
        this.popClosedV2('');
      }
      AppInterface.callApp({
        name: 'popClosed',
      });
    }
  },
  popClosedV2: function () {
    AppInterface.callApp({
      name: 'popClosedV2',
    });
  },
  historyBack: function () {
    AppInterface.callApp({
      name: 'historyBack',
    });
  },
  selfClose: function () {
    AppInterface.callApp({
      name: 'selfClose',
    });
  },
  closePopup: function (refresh, referer) {
    checkAddons(
      'closePopup',
      function () {
        AppInterface.callApp({
          name: 'closePopup',
          param: { refresh, referer },
        });
      },
      Object.values(arguments),
    );
  },
  clearWithUrl: function (url, logouted) {
    AppInterface.callApp({
      name: 'clearWithUrl',
      param: { url, logouted },
    });
  },
  showShare: function () {
    AppInterface.callApp({
      name: 'showShare',
    });
  },
  showAppRating: function () {
    AppInterface.callApp({
      name: 'showAppRating',
    });
  },
  vibrate: function () {
    AppInterface.callApp({
      name: 'vibrate',
    });
  },
  appsflyerCall: function (eventId, params) {
    AppInterface.callApp({
      name: 'appsflyerCall',
      param: { eventId, params },
    });
  },
  showNative: function (appScheme) {
    checkAddons(
      'showNative',
      function () {
        AppInterface.callApp({
          name: 'showNative',
          param: { appScheme },
        });
      },
      Object.values(arguments),
    );
  },
  showShareMeta: function (title, image, url, description, type) {
    AppInterface.callApp({
      name: 'showShareMeta',
      param: { title, image, url, description, type },
    });
  },
  setCookies: function (jsonString) {
    checkAddons(
      'setCookies',
      function () {
        AppInterface.callApp({
          name: 'setCookies',
          param: { jsonString },
        });
      },
      Object.values(arguments),
    );
  },
  sendEvent: function (eventName, properties) {
    AppInterface.callApp({
      name: 'amplitudeEventLog',
      param: { eventName, log: { properties } },
    });
  },
  sendPurchaseEvent: function (log) {
    AppInterface.callApp({
      name: 'amplitudeEventLog',
      param: { eventName: 'purchase', log },
    });
  },
  sendScreenEvent: function (screenName, properties) {
    AppInterface.callApp({
      name: 'amplitudeEventLog',
      param: { eventName: screenName, log: { properties } },
    });
  },
  identifyUser: function (type, key, value) {
    AppInterface.callApp({
      name: 'amplitudeUserIdentify',
      param: { type, key, value },
    });
  },
  checkNotifications: function () {
    return AppInterface.callApp({
      name: 'checkNotifications',
    });
  },
  getCommunityProfileToken: function () {
    return AppInterface.callApp({
      name: 'getCommunityProfileToken',
    });
  },
  moveDeviceNotifications: function () {
    AppInterface.callApp({
      name: 'moveDeviceNotifications',
    });
  },
  showToast: function (message) {
    AppInterface.callApp({
      name: 'showToast',
      param: { message },
    });
  },
  requestAppsFlyerClientData: function () {
    AppInterface.callApp({
      name: 'requestAppsFlyerClientData',
    });
  },
  requestAmplitudeVariant: function (key) {
    AppInterface.callApp({
      name: 'requestAmplitudeVariant',
      param: { key },
    });
  },
  myPhoneNumbers: function () {
    AppInterface.callApp({
      name: 'myPhoneNumbers',
    });
  },
  showOfflineEditorialShopQRScanView: function () {
    AppInterface.callApp({
      name: 'showOfflineEditorialShopQRScanView',
    });
  },
  showContact: function (param) {
    AppInterface.callApp({
      name: 'showContact',
      param,
    });
  },
};

export default appInterface;
