import checkAddons from '../../addons/addons';

const appInterface = {
  eventBannerData: null,
  isReloadWorking: false,
  setEventBanner: function (idx, linkURL, imageURL, subject, content, expires, visible) {
    checkAddons(
      'setEventBanner',
      function (idx, linkURL, imageURL, subject, content, expires, visible) {
        window.AppInterface.callUtils({
          name: 'setEventBanner',
          param: { idx, linkURL, imageURL, subject, content, expires, visible },
        });
      },
      Object.values(arguments),
    );
  },
  getEventBanner: function () {
    return checkAddons(
      'getEventBanner',
      function () {
        return window.AppInterface.callUtils({
          name: 'getEventBanner',
        });
      },
      Object.values(arguments),
    );
  },
  clearEventBanner: function () {
    checkAddons(
      'clearEventBanner',
      function () {
        window.AppInterface.callUtils({
          name: 'clearEventBanner',
        });
      },
      Object.values(arguments),
    );
  },
  reloadForChangeFilterType: function () {
    checkAddons(
      'reloadForChangeFilterType',
      function () {
        window.AppInterface.callUtils({
          name: 'reloadForChangeFilterType',
        });
      },
      Object.values(arguments),
    );
  },
  checkReloadForGlobalFilter: function () {
    window.AppInterface.callUtils({
      name: 'checkReloadForGlobalFilter',
    });
  },
  isIOS: function () {
    return window.AppInterface.callUtils({ name: 'isIOS' });
  },
  isAndroid: function () {
    return window.AppInterface.callUtils({ name: 'isAndroid' });
  },
  // Addons 사용하는 곳이 있음
  commonSnsActionGetUrl: function () {
    return checkAddons(
      'commonSnsActionGetUrl',
      function () {
        return window.AppInterface.callUtils({
          name: 'commonSnsActionGetUrl',
        });
      },
      Object.values(arguments),
    );
  },
};

export default appInterface;
