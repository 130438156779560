import { getCookie } from '../../helper/cookieHelper';
import { getMeta, replaceQueryParam } from '../../helper/documentHelper';

const utilsInterface = {
  // @deprecated eventBanner 2.0 배포 이후 deprecated 됨
  setEventBanner: function () {
    // no-op
  },
  // @deprecated eventBanner 2.0 배포 이후 deprecated 됨
  getEventBanner: function () {
    // no-op
  },
  // @deprecated eventBanner 2.0 배포 이후 deprecated 됨
  clearEventBanner: function () {
    // no-op
  },

  reloadForChangeFilterType: function () {
    if (this.isReloadWorking) return;
    this.isReloadWorking = true;
    window.scrollTo({ top: 0 });
    window.location.reload();
  },

  checkReloadForGlobalFilter: function () {
    const gf = _AppInfo.getGf();
    if (gf === '') return;
    if (!AppInterface.callUtils({ name: 'isIOS' }) && gf !== getCookie('_gf')) {
      // no-cache 페이지(ex 캠페인)에서 대한 reload() 처리
      window.AppInterface.reloadForChangeFilterType();
    }
  },

  isIOS: function () {
    return !!window.webkit?.messageHandlers?.MusinsaAppInterface;
  },

  isAndroid: function () {
    return !!window.MusinsaAppInterface;
  },

  commonSnsActionGetUrl: function () {
    return getMeta('og:url') || document.location.href;
  },

  removeGfParameter: function () {
    let newQueryString = replaceQueryParam('_gf', '', window.location.search);
    if (window.location.search !== newQueryString) {
      if (newQueryString === '') {
        newQueryString = window.location.pathname;
      }
      history.replaceState(null, '', newQueryString + window.location.hash);
    }
  },

  openWebview: function ({ ev }) {
    const event = ev || window.event;
    if (window._AppInfo._os === 'android' && event.currentTarget.getAttribute('target') !== '_blank') {
      event.currentTarget.setAttribute('target', '_blank');
    }
  },
};

export default utilsInterface;
