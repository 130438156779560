import throwError from '../helper/errorHelper';

export default class Amplitude {
  PREFIX = 'data-amp';
  mutationConfig = {
    subtree: true,
    childList: true,
    attributes: true,
  };

  init() {
    this.bindClickEvent();
    this.bindMutationObserver();
  }

  bindMutationObserver() {
    const mutaionObserver = new MutationObserver(this.cbMutation.bind(this));
    mutaionObserver.observe(document.body, this.mutationConfig);
  }

  cbMutation(mutationRecord) {
    const [{ addedNodes, type, target }] = mutationRecord;
    switch (type) {
      case 'childList':
        [...addedNodes].forEach((el) => {
          if (el instanceof HTMLElement && !el.dataset.ampEventType) return;
          this.bindClickEvent(el);
        });
        break;
      case 'attributes':
        const { ampEventType, ampDirty } = target.dataset;
        if ((target instanceof HTMLElement && !ampEventType) || ampDirty === 'true') return;
        this.bindClickEvent(target);
    }
  }

  bindClickEvent(target) {
    const clickElems =
      target instanceof HTMLElement ? [target] : document.body.querySelectorAll(`[${this.PREFIX}-event-type*="click"]`);

    if (!clickElems && !clickElems.length) return;

    [...clickElems].forEach((el) => {
      el.removeEventListener('click', this.sendClickEvent);
      el.addEventListener('click', this.sendClickEvent);
      el.dataset.ampDirty = 'true';
    });
  }

  /**
   * 미사용 메소드
   * data-amp-* 상태값 데이터 객체로 추출
   * @param HTMLElement
   * @returns {key: string}
   */
  getDataset(elem) {
    return [...elem.attributes].reduce(
      (acc, { name, value }) =>
        !name.startsWith(this.PREFIX)
          ? acc
          : {
              ...acc,
              [name.replace(`${this.PREFIX}-`, '').replace(/-/g, '_')]: value,
            },
      {},
    );
  }

  sendClickEvent = ({ currentTarget }) => {
    const { ampEventType, ampEventName, ampProperties } = currentTarget.dataset;
    let props;
    try {
      props = JSON.parse(ampProperties || '{}');
    } catch (err) {
      throwError(`Failed to parse attribute.("${this.PREFIX}-properties")\n${err}`);
    }

    if (!props.from) {
      const fromScreen = window.AppInterface.getScreenName();
      fromScreen?.name && (props.from = fromScreen.name);
    }
    const { tagName, href } = currentTarget;
    if (!props.to && tagName === 'A') {
      const { pathname } = new URL(href);
      if (!pathname.startsWith('#')) {
        const toScreen = window.AppInterface.getScreenName(href);
        toScreen?.name && (props.to = toScreen.name);
      }
    }

    this.sendEvent(ampEventType, ampEventName, props);
  };

  sendEvent(type, name, props) {
    if (type.includes('click')) window.AppInterface.sendEvent(name, props);
  }
}
