import checkAddons from '../../addons/addons';

const appInterface = {
  getOpenGraphInfo: function () {
    return AppInterface.callWeb({ name: 'getOpenGraphInfo' });
  },
  changeGlobalFilterType: function (gf) {
    checkAddons(
      'changeGlobalFilterType',
      function () {
        AppInterface.callWeb({ name: 'changeGlobalFilterType', param: { gf } });
      },
      Object.values(arguments),
    );
  },
  sendCurrentGlobalFilterType: function (currentGf) {
    checkAddons(
      'sendCurrentGlobalFilterType',
      function () {
        AppInterface.callWeb({
          name: 'sendCurrentGlobalFilterType',
          param: { currentGf },
        });
      },
      Object.values(arguments),
    );
  },
  sendDeviceNotification: function (status) {
    return AppInterface.callWeb({ name: 'sendDeviceNotification', param: { status } });
  },
  sendCommunityProfileToken: function (token) {
    return AppInterface.callWeb({ name: 'sendCommunityProfileToken', param: { token } });
  },
  sendAppsFlyerClientData: function (clientData) {
    return AppInterface.callWeb({ name: 'sendAppsFlyerClientData', param: { clientData } });
  },
  sendAmplitudeVariant: function (key, value, payload) {
    return AppInterface.callWeb({ name: 'sendAmplitudeVariant', param: { key, value, payload } });
  },
  uploadMyPhoneNumbers: function (phoneInfo) {
    return AppInterface.callWeb({ name: 'uploadMyPhoneNumbers', param: { phoneInfo } });
  },
  didPressSendMessageButton: function () {
    AppInterface.callWeb({ name: 'didPressSendMessageButton' });
  },
  sendSelectedContact: function (name, phoneNumber) {
    AppInterface.callWeb({ name: 'sendSelectedContact', param: { name, phoneNumber } });
  },
};

export default appInterface;
