const getMeta = (key) => {
  return document.querySelector(`meta[property='${key}']`)?.getAttribute?.('content') || '';
};

const snsActionGetTitle = () => {
  return getMeta('og:title') || document.querySelector('title')?.textContent || '무신사 스토어';
};

const snsActionGetImageUrl = () => {
  return getMeta('og:image') || '//image.msscdn.net/mfile_s01/fb/share_musinsa.png';
};

/**
 * Get Absolute URL
 * @param {string} url
 * @param {string} type
 * @returns
 */
const getAbsURL = (url, type) => {
  if (url.indexOf('//') < 0) {
    const host = type === 'image' ? [location.protocol, 'image.msscdn.net'].join('//') : location.origin;
    return [host, url.replace(/^\/+/, '')].join('/');
  } else if (!/^http(s?):\/\//.test(url)) {
    return [location.protocol, url].join('');
  }
  return url;
};

const getExpires = (expires) => {
  const dateArray = /(\d{4})(\d{2})(\d{2})(\d{2})/.exec(expires);
  return new Date(
    +dateArray[1],
    +dateArray[2] - 1, // Careful, month starts at 0!
    +dateArray[3],
    +dateArray[4],
    0,
    0,
  ).getTime();
};

const replaceQueryParam = (param, newVal, search) => {
  const regex = new RegExp(`([?;&])(${param}=)([^&;]*)`);
  if (search.match(regex) === null) return search;
  return search
    .replace(regex, (_, p1, p2) => {
      return newVal ? [p1, p2, newVal].join('') : '';
    })
    .replace(/^\&/, '?');
};

export { getMeta, snsActionGetTitle, snsActionGetImageUrl, getAbsURL, getExpires, replaceQueryParam };
