const setCookie = function (key, value, expire) {
  const cookieDate = new Date();
  cookieDate.setDate(cookieDate.getDate() + parseInt(expire));
  document.cookie = `${key}=${escape(value)}; expires=${cookieDate.toUTCString()}; path=/; domain=.musinsa.com`;
};

const getCookie = function (key) {
  return document.cookie.match('(^|;) ?' + key + '=([^;]*)(;|$)')?.[2] || null;
};

export { setCookie, getCookie };
