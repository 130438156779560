import { getCookie } from '../helper/cookieHelper';

class AppInfo {
  _isApp = false;
  _os = '';
  _version = '';
  _validGfs = ['A', 'F', 'M'];
  _gf = '';

  constructor() {
    if (typeof window.THIS_PAGE_GF === 'undefined') {
      window.THIS_PAGE_GF = '';
    }
    this.setGf(THIS_PAGE_GF || getCookie('_gf'));
    const userAgent = navigator.userAgent.toLowerCase();
    const appVersionIndex = userAgent.indexOf(';app_ver=');
    this._isApp = appVersionIndex >= 0;
    if (!this._isApp) return;

    const [os, version] = userAgent
      .substr(appVersionIndex + 9, userAgent.length - appVersionIndex)
      .split(';')[0]
      .split(' ');
    this._os = os;
    this._version = version;
  }

  setGf(gf) {
    this._gf = this.isValidGf(gf) ? gf : '';
  }

  getGf() {
    return this._gf;
  }

  isValidGf(gf) {
    return this._validGfs.indexOf(gf) >= 0;
  }

  validateGf(gf) {
    if (!this.isValidGf(gf)) {
      throw ["'", gf, "'", ' is invalid value for GlobalFilter'].join('');
    }
  }

  versionCompare(v1, v2, options) {
    let lexicographical = options && options.lexicographical,
      zeroExtend = options && options.zeroExtend,
      v1parts = v1.split('.'),
      v2parts = v2.split('.');

    function isValidPart(x) {
      return (lexicographical ? /^\d+[A-Za-z]*$/ : /^\d+$/).test(x);
    }

    if (!v1parts.every(isValidPart) || !v2parts.every(isValidPart)) {
      return NaN;
    }

    if (zeroExtend) {
      while (v1parts.length < v2parts.length) v1parts.push('0');
      while (v2parts.length < v1parts.length) v2parts.push('0');
    }

    if (!lexicographical) {
      v1parts = v1parts.map(Number);
      v2parts = v2parts.map(Number);
    }

    for (let i = 0; i < v1parts.length; ++i) {
      if (v2parts.length === i) {
        return 1;
      }

      if (v1parts[i] === v2parts[i]) {
        continue;
      } else if (v1parts[i] > v2parts[i]) {
        return 1;
      } else {
        return -1;
      }
    }

    if (v1parts.length !== v2parts.length) {
      return -1;
    }

    return 0;
  }

  UpperVersion(compareVersion) {
    return this.versionCompare(this._version, compareVersion) >= 0;
  }

  LowerVersion(compareVersion) {
    return this.versionCompare(this._version, compareVersion) < 0;
  }
}

export default AppInfo;
