import { getCookie } from '../../helper/cookieHelper';
import { getAbsURL, getExpires, getMeta, replaceQueryParam } from '../../helper/documentHelper';

const utilsInterface = {
  setEventBanner: function ({ idx, linkURL, imageURL, subject, content, expires, visible }) {
    this.eventBannerData = {
      idx: idx ? String(idx) : '',
      linkURL: linkURL ? getAbsURL(linkURL).trim() : '',
      imageURL: imageURL ? getAbsURL(imageURL).trim() : '',
      subject: subject || '',
      content: content || '',
      expires: getExpires(expires) || 0,
      visible: visible === 'Y',
    };
  },

  getEventBanner: function () {
    const idx = this.eventBannerData ? this.eventBannerData.idx : '';
    if (idx && idx == getCookie(`musinsa_event_banner_${idx}`)) {
      this.clearEventBanner();
    }
    return (
      this.eventBannerData || {
        idx: '', // 이벤트 배너 아이디
        linkURL: '', // 링크
        imageURL: '', // 이미지 경로
        subject: '', // 이벤트 배너 타이틀
        content: '', // 이벤트 배너 컨텐츠
        expires: 0, // 이벤트 배너 만료 일자
        visible: false, // 노출 여부
      }
    );
  },

  clearEventBanner: function () {
    this.eventBannerData = {
      idx: '',
      linkURL: '',
      imageURL: '',
      subject: '',
      content: '',
      expires: '',
      visible: this.eventBannerData.visible || 'N',
    };
  },

  reloadForChangeFilterType: function () {
    if (this.isReloadWorking) return;
    this.isReloadWorking = true;
    window.scrollTo({ top: 0 });
    window.location.reload();
  },

  checkReloadForGlobalFilter: function () {
    const gf = _AppInfo.getGf();
    if (gf === '') return;
    if (!AppInterface.callUtils({ name: 'isIOS' }) && gf !== getCookie('_gf')) {
      // no-cache 페이지(ex 캠페인)에서 대한 reload() 처리
      window.AppInterface.reloadForChangeFilterType();
    }
  },

  isIOS: function () {
    return !!window.webkit?.messageHandlers?.MusinsaAppInterface;
  },

  isAndroid: function () {
    return !!window.MusinsaAppInterface;
  },

  commonSnsActionGetUrl: function () {
    return getMeta('og:url') || document.location.href;
  },

  removeGfParameter: function () {
    let newQueryString = replaceQueryParam('_gf', '', window.location.search);
    if (window.location.search !== newQueryString) {
      if (newQueryString === '') {
        newQueryString = window.location.pathname;
      }
      history.replaceState(null, '', newQueryString + window.location.hash);
    }
  },

  openWebview: function ({ ev }) {
    const event = ev || window.event;
    if (window._AppInfo._os === 'android' && event.currentTarget.getAttribute('target') !== '_blank') {
      event.currentTarget.setAttribute('target', '_blank');
    }
  },
};

export default utilsInterface;
