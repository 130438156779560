import { getCookie, setCookie } from './cookieHelper';

const GLOBAL_FILTER_KEY = 'gf';
const GLOBAL_FILTER_COOKIE_KEY = '_gf';
// const GENDER_QUERY_KEY = 'gender';
const ONE_YEAR = 365;
const GENDER_KEY = {
  A: 'A',
  M: 'M',
  F: 'F',
};

const setAppInfoGf = (gf) => {
  _AppInfo.validateGf(gf);
  _AppInfo.setGf(gf);
};

const setCookieToUnderBarGlobalFilterGf = (gf) => {
  setCookie(GLOBAL_FILTER_COOKIE_KEY, gf, ONE_YEAR);
};

const setGender = (gf) => {
  setCookieToUnderBarGlobalFilterGf(gf);
  setAppInfoGf(gf);
};

const getQueryUnderBarGlobalFilterGf = (search = location.search) => {
  const searchParams = new URLSearchParams(search);
  if (searchParams.has(GLOBAL_FILTER_COOKIE_KEY)) {
    return searchParams.get(GLOBAL_FILTER_COOKIE_KEY);
  }
};

const getGf = (search = location.search) => {
  const searchParams = new URLSearchParams(search);
  if (searchParams.has(GLOBAL_FILTER_KEY)) {
    return searchParams.get(GLOBAL_FILTER_KEY);
  }
};

const isInvalidGender = (value) => value in GENDER_KEY;

const setInvalidGlobalFilterToGenderValue = (value) => (isInvalidGender(value) ? value : 'A');

export function syncGenderQueryToCookie(search = location.search) {
  const gf = getGf(search);
  const searchParams = new URLSearchParams(search);
  const underBarGlobalFilterGf = getQueryUnderBarGlobalFilterGf(search);
  const cookieUnderBarGlobalFilterGf = getCookie(GLOBAL_FILTER_COOKIE_KEY);
  let genderValue = GENDER_KEY.A;

  if (underBarGlobalFilterGf) {
    genderValue = setInvalidGlobalFilterToGenderValue(underBarGlobalFilterGf);
    searchParams.delete(GLOBAL_FILTER_COOKIE_KEY);
  } else if (gf) {
    genderValue = setInvalidGlobalFilterToGenderValue(gf);
  } else if (cookieUnderBarGlobalFilterGf && cookieUnderBarGlobalFilterGf !== searchParams.get(GLOBAL_FILTER_KEY)) {
    genderValue = setInvalidGlobalFilterToGenderValue(cookieUnderBarGlobalFilterGf);
  }

  searchParams.set(GLOBAL_FILTER_KEY, genderValue);
  setGender(genderValue);
  history.replaceState(null, '', `?${searchParams.toString()}`);

  return;
}
