const isAndroid = () => (window.MusinsaAppInterface ? true : false);
const isIOS = () => (window.webkit?.messageHandlers?.MusinsaAppInterface ? true : false);

const deviceCheck = () => {
  let device;
  switch (true) {
    case isAndroid():
      device = 'android';
      break;
    case isIOS():
      device = 'ios';
      break;
    case !isAndroid() && !isIOS():
      device = 'notMobile';
      break;
    default:
  }

  return device;
};

// 디바이스별 콜앱 실행
const deviceCallApp = function (device, parameter) {
  const callApp = {
    android: (v) => {
      const message = device?.android(v);
      if (!message?.name && !Array.isArray(message?.param))
        throw 'Invalid argument value for android function in deviceCallApp.';
      return window.MusinsaAppInterface?.[message.name]?.(...message.param);
    },
    ios: (v) => {
      const message = device?.ios(v);
      return window.webkit.messageHandlers?.MusinsaAppInterface?.postMessage(message);
    },
    notMobile: (v) => device?.notMobile(v),
    router: function (parameter) {
      const currentDevice = deviceCheck();
      if (typeof device[currentDevice] !== 'function') return;
      return this[currentDevice]?.(parameter);
    },
  };

  return callApp.router(parameter);
};

const isProd = (() => {
  try {
    const { origin } = new URL(location.href);
    return !['alpha', 'dev', 'local'].map((i) => origin.includes(i)).filter((i) => i).length;
  } catch (e) {
    console.warn(e);
  }
  return true;
})();

export { isAndroid, isIOS, deviceCheck, deviceCallApp, isProd };
