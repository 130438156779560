import commonInterface from './interface/callApp/commonInterface';
import callAppAppInterface from './interface/callApp/appInterface';
import { objectMerge } from './helper/mergeHelper';
import makeParameter from './helper/makeParameter';
import interfaceValidator from './helper/validateHelper';
import { deviceCallApp } from './helper/deviceHelper';
import getScreenName from './helper/screenHelper';
import commonWebInterface from './interface/callWeb/commonInterface';
import callWebAppInterface from './interface/callWeb/appInterface';
import AppInfo from './appInfo/appInfo';
import Utils from './interface/utils/commonInterface';
import utilsAppInterface from './interface/utils/appInterface';
import Amplitude from './amplitude';
import { syncGenderQueryToCookie } from './helper/genderHelper';

window.AppInterface = objectMerge(window.AppInterface ?? {}, {
  ...callAppAppInterface,
  ...callWebAppInterface,
  ...utilsAppInterface,
});

window.AppInterface.callApp = (function () {
  const _callApp = function (rootObj) {
    const state = objectMerge(commonInterface['default'], commonInterface[rootObj.name]);
    const { validation, beforeCallback, android, ios, notMobile, isStringify } = state;

    // commonInterface에 등록된 validation 검증
    if (interfaceValidator.call(this, rootObj, validation)) return;
    const parameter = makeParameter.call(this, rootObj, beforeCallback, isStringify);

    // 디바이스별 콜앱 호출
    return deviceCallApp({ android, ios, notMobile }, parameter);
  };

  const callApp = function (obj) {
    if (!commonInterface[obj.name]) throw `${obj.name} is undefined.`;
    return _callApp.call(this, obj);
  };

  return callApp;
})();

window.AppInterface.callWeb = (function () {
  const callWeb = function (obj) {
    if (!commonWebInterface[obj.name]) throw `${obj.name} is undefined.`;
    return commonWebInterface[obj.name].call(this, obj.param ?? {});
  };
  return callWeb;
})();

window.AppInterface.callUtils = (function () {
  const callUtils = function (obj) {
    if (!Utils[obj.name]) throw `${obj.name} is undefined.`;
    return Utils[obj.name].call(this, obj.param ?? {});
  };
  return callUtils;
})();

window._AppInfo = new AppInfo();

(function () {
  if (window?.__MSS_FE__?.config?.gender?.syncQueryToCookie) {
    syncGenderQueryToCookie();
  } else {
    AppInterface.callUtils({ name: 'removeGfParameter' });
    AppInterface.callUtils({ name: 'checkReloadForGlobalFilter' });
  }
})();

window.AppInterface.openWebview = (ev) => {
  AppInterface.callUtils({ name: 'openWebview', param: { ev } });
};

window.AppInterface.getScreenName = getScreenName;

const amplitude = new Amplitude();
window.addEventListener('DOMContentLoaded', () => {
  amplitude.init();
});
