const getMeta = (key) => {
  return document.querySelector(`meta[property='${key}']`)?.getAttribute?.('content') || '';
};

const snsActionGetTitle = () => {
  return getMeta('og:title') || document.querySelector('title')?.textContent || '무신사 스토어';
};

const snsActionGetImageUrl = () => {
  return getMeta('og:image') || '//image.msscdn.net/mfile_s01/fb/share_musinsa.png';
};

const replaceQueryParam = (param, newVal, search) => {
  const regex = new RegExp(`([?;&])(${param}=)([^&;]*)`);
  if (search.match(regex) === null) return search;
  return search
    .replace(regex, (_, p1, p2) => {
      return newVal ? [p1, p2, newVal].join('') : '';
    })
    .replace(/^\&/, '?');
};

export { getMeta, snsActionGetTitle, snsActionGetImageUrl, replaceQueryParam };
