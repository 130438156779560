import screenMap from '../../../screen-map.json';

const getScreenName = function (url = window.location.href) {
  let result = 'none';
  try {
    const { pathname, searchParams } = new URL(url);
    const screens = screenMap.screens;
    for (let i = 0; i < screens.length; i++) {
      let [reg = '', name = ''] = screens[i];
      const regExp = new RegExp(reg);
      const regResult = regExp.exec(decodeURIComponent(pathname));

      if (regResult) {
        result = { name: `screen_${name}` };
        regResult?.groups && (result.groups = regResult.groups);

        for (const [key, value] of searchParams) {
          result.params = {
            ...result.params,
            [key]: value,
          };
        }

        break;
      }
    }
  } catch (e) {
    console.warn(e);
  }
  return result;
};

export default getScreenName;
