import checkAddons from '../../addons/addons';

const appInterface = {
  isReloadWorking: false,
  eventBannerData: null,
  // @deprecated eventBanner 2.0 배포 이후 deprecated 됨
  setEventBanner: function () {
    // no-op
  },
  // @deprecated eventBanner 2.0 배포 이후 deprecated 됨
  getEventBanner: function () {
    // no-op;
  },
  // @deprecated eventBanner 2.0 배포 이후 deprecated 됨
  clearEventBanner: function () {
    // no-op;
  },
  reloadForChangeFilterType: function () {
    checkAddons(
      'reloadForChangeFilterType',
      function () {
        window.AppInterface.callUtils({
          name: 'reloadForChangeFilterType',
        });
      },
      Object.values(arguments),
    );
  },
  checkReloadForGlobalFilter: function () {
    window.AppInterface.callUtils({
      name: 'checkReloadForGlobalFilter',
    });
  },
  isIOS: function () {
    return window.AppInterface.callUtils({ name: 'isIOS' });
  },
  isAndroid: function () {
    return window.AppInterface.callUtils({ name: 'isAndroid' });
  },
  // Addons 사용하는 곳이 있음
  commonSnsActionGetUrl: function () {
    return checkAddons(
      'commonSnsActionGetUrl',
      function () {
        return window.AppInterface.callUtils({
          name: 'commonSnsActionGetUrl',
        });
      },
      Object.values(arguments),
    );
  },
};

export default appInterface;
