import { setCookie } from '../../helper/cookieHelper';
import { getMeta, snsActionGetTitle, snsActionGetImageUrl } from '../../helper/documentHelper';

const commonWebInterface = {
  getOpenGraphInfo: function () {
    const price = getMeta('product:price:amount');
    const isGoodsDetailPage = !!price;

    return {
      type: isGoodsDetailPage ? 'product' : 'default',
      title: snsActionGetTitle(),
      image: snsActionGetImageUrl(),
      url: window.AppInterface.commonSnsActionGetUrl(),
      description: getMeta('og:description'),
      brand: isGoodsDetailPage ? getMeta('product:brand') : '',
      currency: isGoodsDetailPage ? getMeta('product:price:currency') : '',
      price,
      normal_price: isGoodsDetailPage ? getMeta('product:price:normal_price') : '',
      sale_rate: isGoodsDetailPage ? getMeta('product:price:sale_rate') : '',
      show_share_button_yn: window.commonLayout?.shareOptions?.setBottomMenu?.isShowShareButton ? 'Y' : 'N',
      send_share_analytics_yn: getMeta('og:send_share_analytics_yn') || 'N',
      isNeedShortLink: (function () {
        const needShortLink = getMeta('og:need_short_link');
        return needShortLink !== 'false';
      })(),
    };
  },
  changeGlobalFilterType: function ({ gf }) {
    _AppInfo.validateGf(gf);
    setCookie('_gf', gf, 365);
    _AppInfo.setGf(gf);
    window.AppInterface.reloadForChangeFilterType();
  },
  sendCurrentGlobalFilterType: function ({ currentGf }) {
    if (_AppInfo.getGf() === '') {
      window.dispatchEvent(new CustomEvent('appWebLoad'));
    } else if (_AppInfo.getGf() !== currentGf) {
      setCookie('_gf', currentGf, 365);
      _AppInfo.setGf(currentGf);
      window.AppInterface.reloadForChangeFilterType();
    } else {
      window.AppInterface.eventBanner();
      window.dispatchEvent(new CustomEvent('appWebLoad'));
    }
  },
  sendDeviceNotification: function ({ status }) {
    window.dispatchEvent(new CustomEvent('sendDeviceNotification', { detail: { status } }));
  },
  sendCommunityProfileToken: function ({ token }) {
    window.dispatchEvent(new CustomEvent('sendCommunityProfileToken', { detail: { token } }));
  },
  sendAppsFlyerClientData: function ({ clientData }) {
    window.dispatchEvent(new CustomEvent('sendAppsFlyerClientData', { detail: JSON.parse(clientData) }));
  },
  sendAmplitudeVariant: function ({ key, value, payload }) {
    const payloadParsingData = !!payload ? JSON.parse(payload) : null;
    window.dispatchEvent(
      new CustomEvent('sendAmplitudeVariant', { detail: { key, value, payload: payloadParsingData } }),
    );
  },
  uploadMyPhoneNumbers: function ({ phoneInfo }) {
    window.dispatchEvent(new CustomEvent('uploadMyPhoneNumbers', { detail: JSON.parse(phoneInfo) }));
  },
  didPressSendMessageButton: function () {
    window.dispatchEvent(new CustomEvent('didPressSendMessageButton'));
  },
  sendSelectedContact: function ({ name, phoneNumber }) {
    window.dispatchEvent(new CustomEvent('sendSelectedContact', { detail: { name, phoneNumber } }));
  },
};

export default commonWebInterface;
