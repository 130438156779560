import { isProd } from '../../helper/deviceHelper';

const commonInterface = {
  default: {
    isStringify: true,
    validation: [],
    beforeCallback: undefined,
    android: function (data) {
      return {
        name: data.name,
        param: [data.param],
      };
    }, //, false, function
    ios: function (data) {
      const message = {
        command: data.name,
        name: null,
        parameters: data.param,
      };
      return message;
    },
    notMobile: false,
  },
  eventBanner: {
    isStringify: false,
    beforeCallback: function () {
      return window.AppInterface.getEventBanner();
    },
    android: function (data) {
      return {
        name: data.name,
        param: [JSON.stringify(data.param)],
      };
    },
    ios: function (data) {
      // Call iOS interface
      return {
        command: data.name,
        name: null,
        parameters: data.param,
      };
    },
    notMobile: false,
  },
  /**
   * Web -> App Global Filter 값 연동
   * @param {string} type
   */
  setGlobalFilter: {
    isStringify: false,
    validation: [
      {
        validate: function (rootObj) {
          // type 정의야함
          return _AppInfo.validateGf(rootObj?.param?.type);
        },
        result: function () {},
      },
    ],
    android: function (data) {
      return {
        name: data.name,
        param: [data.param.type],
      };
    },
    ios: function (data) {
      return {
        command: data.name,
        name: null,
        parameters: { type: data.param.type },
      };
    },
  },
  popOpened: {
    isStringify: false,
    android: function (data) {
      return {
        name: data.name,
        param: [data.param.messageName],
      };
    },
    ios: function (data) {
      // Call iOS interface
      return {
        command: data.name,
        name: data.param.messageName,
        value: null,
      };
    },
    notMobile: function () {
      // No Android or iOS interface found
      console.log('No native APIs found.');
    },
  },
  popClosed: {
    isStringify: false,
    android: function (data) {
      return {
        name: data.name,
        param: [],
      };
    },
    ios: function (data) {
      return {
        command: data.name,
        name: null,
        value: null,
      };
    },
    notMobile: function () {
      // No Android or iOS interface found
      console.log('No native APIs found.');
    },
  },
  popClosedV2: {
    isStringify: true,
    android: function (data) {
      return {
        name: data.name,
        param: [''],
      };
    },
    ios: function (data) {
      return {
        command: data.name,
        name: null,
        parameters: JSON.stringify({
          // NOTE 4.0.0 이상 앱에서 GNB 가 나오지 않아야 하는 지면에서 노출되는 케이스 확인용 parameter
          currentUrl: `${window.location.host}${window.location.pathname}`,
        }),
      };
    },
    notMobile: function () {
      // No Android or iOS interface found
      console.log('No native APIs found.');
    },
  },
  historyBack: {
    isStringify: false,
    android: function (data) {
      return {
        name: data.name,
        param: [],
      };
    },
    ios: function (data) {
      return {
        command: data.name,
        name: null,
        value: null,
      };
    },
    notMobile: function () {
      /**
       * NOTE 새창정책에 따라 window.history.length 가 1일 경우 window.close() 호출
       * @see https://wiki.musinsa.com/pages/viewpage.action?pageId=207952728
       */
      if (window.history.length === 1) {
        window.close();
        return;
      }
      window.history.back();
    },
  },
  selfClose: {
    isStringify: false,
    android: function (data) {
      return {
        name: 'historyBack',
        param: [],
      };
    },
    ios: function (data) {
      return {
        command: 'historyBack',
        name: null,
        value: null,
      };
    },
    notMobile: function () {
      self.close();
    },
  },
  closePopup: {
    isStringify: false,
    android: function (data) {
      return {
        name: data.name,
        param: [data.param.refresh, data.param.referer],
      };
    },
    ios: function (data) {
      return {
        command: data.name,
        name: null,
        parameters: {
          refresh: data.param.refresh,
          referer: data.param.referer,
        },
      };
    },
    notMobile: false,
  },
  clearWithUrl: {
    isStringify: false,
    android: function (data) {
      return {
        name: data.name,
        param: [data.param.url, data.param.logouted],
      };
    },
    ios: function (data) {
      return {
        command: data.name,
        name: null,
        parameters: { url: data.param.url, logouted: data.param.logouted },
      };
    },
    notMobile: function (data) {
      window.location.href = data.param.url;
    },
  },
  showShare: {
    isStringify: false,
    android: function (data) {
      return {
        name: data.name,
        param: [],
      };
    },
    ios: function (data) {
      return {
        command: data.name,
        name: null,
        parameters: null,
      };
    },
    notMobile: function () {
      console.log('No native APIs found.');
    },
  },
  showAppRating: {
    isStringify: false,
    android: function (data) {
      return {
        name: data.name,
        param: [],
      };
    },
    ios: function (data) {
      return {
        command: data.name,
        name: null,
        parameters: null,
      };
    },
    notMobile: function () {
      console.log('No native APIs found.');
    },
  },
  /**
   * 햅틱.
   */
  vibrate: {
    isStringify: false,
    validation: [
      {
        validate: function () {
          return _AppInfo._isApp;
        },
        result: function () {
          return;
        },
      },
    ],
    android: function (data) {
      return {
        name: data.name,
        param: [],
      };
    },
    ios: function (data) {
      return {
        command: data.name,
        name: null,
        parameters: null,
      };
    },
  },
  appsflyerCall: {
    isStringify: false,
    android: function (data) {
      const strParams = JSON.stringify(data.param.params);
      return {
        name: data.name,
        param: [data.param.eventId, strParams],
      };
    },
    ios: function (data) {
      return {
        command: data.name,
        name: null,
        parameters: {
          eventId: data.param.eventId,
          params: data.param.params,
        },
      };
    },
  },
  showNative: {
    isStringify: false,
    android: function (data) {
      return {
        name: data.name,
        param: [data.param.appScheme],
      };
    },
    ios: function (data) {
      return {
        command: data.name,
        name: null,
        parameters: {
          appScheme: data.param.appScheme,
        },
      };
    },
    notMobile: false,
  },
  showShareMeta: {
    beforeCallback: function (rootObj) {
      return {
        title: rootObj.param.title ?? '',
        image: rootObj.param.image ?? '',
        url: rootObj.param.url ?? '',
        description: rootObj.param.description ?? '',
        type: rootObj.param.type ?? '',
      };
    },
    android: function (data) {
      return {
        name: data.name,
        param: [data.param],
      };
    },
    ios: function (data) {
      return {
        command: data.name,
        name: null,
        parameters: {
          jsonString: data.param,
        },
      };
    },
  },
  setCookies: {
    isStringify: false,
    android: false,
    ios: function (data) {
      return {
        command: data.name,
        name: null,
        parameters: {
          jsonString: data.param.jsonString,
        },
      };
    },
  },
  amplitudeEventLog: {
    isStringify: true,
    android: function (data) {
      const { name, param } = data;
      return {
        name,
        param: [param],
      };
    },
    ios: function (data) {
      const { name: command, param } = data;
      return {
        command,
        name: null,
        parameters: param,
      };
    },
    notMobile: function (data) {
      if (!isProd) {
        const { param: params } = data;
        const param = JSON.parse(params);
        console.log('========================================================================================');
        console.log(`event name: %c${param.eventName}`, 'color: red;');
        console.log('properties:', param.log.properties);
        console.log('log:', param.log);
        console.log('========================================================================================');
      }
      console.log('No native APIs found.');
    },
  },
  amplitudeUserIdentify: {
    isStringify: false,
    android: function (data) {
      const {
        name,
        param: { type, key, value },
      } = data;
      return {
        name,
        param: [JSON.stringify({ [type]: { [key]: value } })],
      };
    },
    ios: function (data) {
      const {
        name: command,
        param: { type, key, value },
      } = data;
      return {
        command,
        name: null,
        parameters: JSON.stringify({ [type]: { [key]: value } }),
      };
    },
    notMobile: function (data) {
      if (!isProd) {
        console.log('========================================================================================');
        console.log('properties:', data.param);
        console.log('========================================================================================');
      }
      console.log('No native APIs found.');
    },
  },
  /**
   * 디바이스의 앱 알림 설정의 켜져있는지 유무를 리턴
   */
  checkNotifications: {
    isStringify: false,
    android: function (data) {
      return {
        name: data.name,
        param: [],
      };
    },
    ios: function (data) {
      return {
        command: data.name,
        name: null,
        parameters: null,
      };
    },
  },
  /**
   * 스냅, 패션톡에 설정 된 토큰 조회 시 사용
   */
  getCommunityProfileToken: {
    isStringify: false,
    android: function (data) {
      return {
        name: data.name,
        param: [''],
      };
    },
    ios: function (data) {
      return {
        command: data.name,
        name: null,
        parameters: '',
      };
    },
  },
  /**
   * 디바이스의 앱 알림 설정 화면으로 이동
   */
  moveDeviceNotifications: {
    isStringify: false,
    android: function (data) {
      return {
        name: data.name,
        param: [],
      };
    },
    ios: function (data) {
      return {
        command: data.name,
        name: null,
        parameters: null,
      };
    },
  },
  showToast: {
    isStringify: false,
    android: function (data) {
      return {
        name: data.name,
        param: [JSON.stringify(data.param)],
      };
    },
    ios: function (data) {
      return {
        command: data.name,
        name: null,
        parameters: JSON.stringify(data.param),
      };
    },
  },
  requestAppsFlyerClientData: {
    isStringify: false,
    android: function (data) {
      return {
        name: data.name,
        param: [],
      };
    },
    ios: function (data) {
      return {
        command: data.name,
        name: null,
        parameters: null,
      };
    },
  },
  myPhoneNumbers: {
    isStringify: false,
    android: function (data) {
      return {
        name: data.name,
        param: [],
      };
    },
    ios: function (data) {
      return {
        command: data.name,
        name: null,
        parameters: null,
      };
    },
  },
  requestAmplitudeVariant: {
    isStringify: false,
    android: function (data) {
      return {
        name: data.name,
        param: [JSON.stringify(data.param)],
      };
    },
    ios: function (data) {
      return {
        command: data.name,
        name: null,
        parameters: JSON.stringify(data.param),
      };
    },
  },
  showOfflineEditorialShopQRScanView: {
    isStringify: false,
    android: function (data) {
      return {
        name: data.name,
        param: [],
      };
    },
    ios: function (data) {
      return {
        command: data.name,
        name: null,
        parameters: null,
      };
    },
  },
  showContact: {
    isStringify: false,
    android: function (data) {
      return {
        name: data.name,
        param: [JSON.stringify(data.param)],
      };
    },
    ios: function (data) {
      return {
        command: data.name,
        name: null,
        parameters: JSON.stringify(data.param),
      };
    },
  },
};

export default commonInterface;
