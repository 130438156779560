function checkAddons(funcName, originFunc, args) {
  if (!Array.isArray(args)) {
    args = [];
  }
  if (window.AppInterfaceAddons?.[funcName] && typeof window.AppInterfaceAddons[funcName] === 'function') {
    args.unshift(originFunc);
    return window.AppInterfaceAddons[funcName].apply(this, args);
  }
  return originFunc.apply(this, args);
}

export default checkAddons;
