const interfaceValidator = function (rootObj, validation) {
  for (let i = 0; i < validation.length; i++) {
    const { validate, result } = validation[i];
    if (validate.call(this, rootObj)) continue;
    return result.call(this);
  }
  return false;
};

export default interfaceValidator;
